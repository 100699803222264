import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify';
import ElementUI from 'element-ui'; // 导入 Element UI
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element UI 样式
import axios from 'axios';
import SlideVerify from './lib/index'

Vue.config.productionTip = false
Vue.use(SlideVerify)
Vue.config.productionTip = false
Vue.prototype.$axios = axios

// 创建一个全局Axios实例
// const axiosInstance = axios.create({
//   baseURL: 'https://confluxdapp.cn/', // 设置基础URL
//   timeout: 10000, // 设置请求超时时间（单位：毫秒）
// });

// 将Axios实例添加到Vue原型中，以便在所有组件中都可以使用this.$http或this.$axios访问
// Vue.prototype.$http = axiosInstance;
// 或者使用this.$axios
// Vue.prototype.$axios = axiosInstance;

Vue.use(Vuetify);

const vuetify = new Vuetify();
Vue.use(ElementUI); // 使用 Element UI
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
