<template>
  <div class="down_icon" @click="handleClick">
    <img class="max-icon" src="@/assets/svg/top-max.svg">
    <!-- <img class="min-icon" style="position: absolute;left: 18px;" src="@/assets/svg/top-min.svg" @click="prevSlide"> -->
  </div>
</template>
      
<script>
export default {
  methods: {
    handleClick() {
      // 在这里调用父组件传递过来的点击事件处理方法
      this.$emit('click');
    }
  }
}
</script>
