<template>
  <div @click="handleClickOutside">
    <el-carousel height="100vh" direction="vertical" :autoplay="false" arrow="never" indicator-position="none"
      :loop="false" ref="carousel" @change="handleOuterChange">
      <el-carousel-item class="carousel-item" style="display: flex">
        <SlideVerify v-if="isSlideVerifyVisible" ref="slideblock" @success="onSuccess" @again="onAgain"
          @fulfilled="onFulfilled" @fail="onFail" @refresh="onRefresh" @clock="onClock" :slider-text="text" :imgs="imgs"
          :accuracy="accuracy"></SlideVerify>
        <div class="item-content" style="margin-bottom: 100px; ">
          <div style="display: flex; align-items: center;">
            <div>
              <img src="@/assets/svg/logo.png" class="logo-img" alt="Conflux Dapp" />
            </div>
            
            <span class="conflux_dapp">Conflux Dapp</span>
          </div>

          <div class="update_info version-text" >版本号{{ version_name }}</div>
          <div class="update_info_div" style="text-align: left;">
            <span class="version-description" v-for="(info, index) in formattedUpdateInfo" :key="index">
              {{ info }} 
            </span>
          </div>
          <div style="display: flex; position: absolute; bottom: 120px">
            <!--  v-loading.fullscreen.lock="fullscreenLoading" -->
            <div class="icon_div"
              @click.prevent="showSlideVerify('')">
              <a download style="display: contents">
                <img style="width: 40px; height: 40px" src="@/assets/svg/Android.svg" alt="Android" />
                <span>Android</span>
              </a>
            </div>
            <div class="icon_div">
              <a href="https://play.google.com/store/apps/details?id=com.laypig.confitransfer" style="display: contents">
                <img style="width: 40px; height: 40px" src="@/assets/svg/Android.svg" alt="Android" />
                <span>Google</span>
              </a>
            </div>
            <div class="icon_div" plain @click="open">
              <a slot="reference" style="display: contents">
                <img style="width: 35px; height: 35px; margin-bottom: 5px" src="@/assets/svg/Iphone.svg" alt="iphone" />
                <span>IOS</span>
              </a>
            </div>
          </div>
          <!-- 背景图-top -->
          <div class="background-image-top" :class="{ animate_top: isAnimating_top }"></div>

          <!-- 背景图-down -->
          <div class="background-image-down" :class="{ animate_down: isAnimating_down }"></div>
        </div>
      </el-carousel-item>

      <!-- 外层轮播 收购-->
      <!-- <el-carousel-item class="carousel-item" style="display: flex; flex-direction: column; align-items: center">
        <div class="carousel-item-content" style="text-align: center" :style="{
          opacity: this.currentItemId === this.currentIndex ? 1 : 0,
        }">
          <div class="acquisition_of">
            <span class="title" style="font-size: 56px; line-height: 65.97px">Acquisition of</span>
            <img src="@/assets/svg/SEARCHFI.svg" alt="SEARCHFI" />
          </div>
          <span class="title_content searchfi">SearchFi is a marketing team with a global NFT
            community</span>
        </div> -->
      <!-- <el-row class="acquisition_data" justify="center" align="middle" :style="{
          opacity: this.currentItemId === this.currentIndex ? 1 : 0,
        }">
          <el-col :span="8">
            <div>
              <span>550k</span>
              <span>Users</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>3,000</span>
              <span>Collaborations</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="flex: 0.8; text-align: center">
              <span>Covering</span>
              <span>East Asia, Southeast Asia</span>
            </div>
          </el-col>
        </el-row> -->
      <!-- 
        <el-row class="three_icon" style="position: absolute;bottom: 17%;" :style="{
          opacity: this.currentItemId === this.currentIndex ? 1 : 0,
        }">
          <el-col :span="8">
            <a href="https://x.com/confluxdapp" target="confluxdapp">
              <img src="@/assets/svg/searchfi_eth.svg" class="searchfi_eth" alt="" />
            </a>
          </el-col>

          <el-col :span="12">
            <el-button round class="learn_more"><a href="https://forum.conflux.fun/c/Chinese/ConfluxDapp">Learn
                more</a></el-button>
          </el-col>
        </el-row>
      </el-carousel-item> -->

      <!-- <el-row style="top: 11.5%; justify-content: center" v-show="showQblackIcon">
        <div class="q-image-class">
          <img class="Q-icon" src="@/assets/svg/Q-black-icon.svg" @click="prevSlide" />
        </div>
      </el-row> -->
      <el-row style="top: 11.5%; justify-content: center">
        <div class="q-image-class">
          <img class="Q-icon" src="@/assets/svg/Q-white-icon.svg" v-show="showDownWhiteIcon" @click="prevSlide" />
        </div>
      </el-row>
      <!-- <div class="grow_together confluxdapp" style="font-size: 20px;top: 3.5%;text-align: center;position: relative;">
        <a href="https://x.com/confluxdapp" target="confluxdapp" style="padding-left: 14px;">
          <img src="@/assets/svg/searchfi_eth.svg" class="searchfi_eth" alt="" />
        </a>
        <span style="cursor: pointer;">中文</span> |
        <span style="">EN</span>
      </div> -->
      <!-- <DownIcon @click="nextSlide" v-show="showDownIcon"></DownIcon>
      <DownWhiteIcon @click="nextSlide" v-show="showDownWhiteIcon"></DownWhiteIcon> -->
      <!-- <TopIcon @click="prevSlide" v-show="showTopIcon"></TopIcon> -->
      <div class="down_icon" >
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#B8B8C0;position: absolute;top: 25px;">滇 ICP 备17008894号-3</a>
      </div>
      <div class="down_icon" @click="prevSlide" v-show="showTopIcon">
        <div>
          <img class="max-icon" src="@/assets/svg/top-max2.svg" />
          <!-- <img class="min-icon" style="position: absolute;left: 18px;" src="@/assets/svg/top-min.svg"> -->
        </div>
      </div>
    </el-carousel>
  </div>
</template>

<script>
import DownIcon from "../components/downIcon.vue";
import TopIcon from "../components/topIcon.vue";
import DownWhiteIcon from "../components/downWhiteIcon.vue";
import SlideVerify from "../lib/slide-verify.vue";
import { debounce } from "lodash";
import img0 from "../assets/validate/png/img0.png";
import img1 from "../assets/validate/png/img0.png";
import img2 from "../assets/validate/png/img0.png";

export default {
  components: {
    DownIcon,
    TopIcon,
    DownWhiteIcon,
    SlideVerify,
  },
  data() {
    return {
      // fullscreenLoading: false,
      isSlideVerifyVisible: false,
      msg: "",
      text: "向右拖动滑块填充拼图",
      imgs: [img0, img1, img2],
      accuracy: 1, // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      isAnimating_top: false,
      isAnimating_down: false,
      currentItemId: 1,
      progressPercentage: 0,
      showDownIcon: true,
      showDownWhiteIcon: false,
      showTopIcon: false,
      currentIndex: 0,
      totalItems: 0,
      isHovered: false,
      visible: false,
      popoverVisible: false,
      showQblackIcon: false,
      isScrolling: false,
      isLoadAnimate: false,
      scaledValue: 1, // 初始缩放值
      updateInfo: "",
      version_name: "",
      apkUrl: "https://confluxdapp.cn/updateapk/confluxdapp.apk"
    };
  },
  computed: {
    shouldShowProgressBar() {
      return this.currentIndex >= 2 && this.currentIndex <= 4;
    },
    formattedUpdateInfo() {
      return this.updateInfo.split("\n").filter((info) => info.trim() !== "");
    },
  },
  mounted() {
    this.checkResize();
    window.addEventListener("resize", this.checkResize);
  },
  methods: {
    open() {
      const h = this.$createElement;
      this.$notify({
        title: '温馨提示',
        message: h('p', { style: 'color: teal' }, '测试名额已满，暂不支持申请测试资格')
      });
    },
    handleClickOutside(event) {
      if (this.$refs.slideblock && !this.$refs.slideblock.$el.contains(event.target)) {
        this.isSlideVerifyVisible = false;
        center: true
      }
    },
    onSuccess(times) {
      this.$message.success(`验证通过, 耗时${(times / 1000).toFixed(1)}s`);
      // 验证通过后执行链接的访问
      if (this.linkToOpen) {
        window.open(this.linkToOpen, '_self')
        // window.location.href = this.linkToOpen;
        setTimeout(() => {
          this.isSlideVerifyVisible = false;
        }, 500);
      }
    },
    onFail() {
      this.$message.error('验证不通过，请再来一次');
    },
    onRefresh() {
      console.log('点击了刷新小图标');
    },
    onClock() {
      this.isSlideVerifyVisible = false;
    },
    onFulfilled() {
      console.log('刷新成功啦！');
    },
    onAgain() {
      console.log('检测到非人为操作的哦！');
      // this.msg = 'try again';
      // 刷新
      this.handleClick();
    },
    handleClick() {
      this.$refs.slideblock.reset();
      // this.msg = ''
    },
    showSlideVerify(link) {
      let that = this
      // this.fullscreenLoading = true;
      this.$nextTick(() => {
        setTimeout(() => {
          // this.fullscreenLoading = false;
          this.linkToOpen = that.apkUrl;
          this.isSlideVerifyVisible = true;
        }, 0);
      });
    },
    checkResize() {
      // 生成随机数
    const randomNumber = Math.floor(Math.random() * 1000);
      this.$axios
      .get(`checkUpdateVersion.json?v=${randomNumber}`)
        .then((response) => {
          // console.log(response.data);
          this.version_name = response.data.version_name;
          this.updateInfo = response.data.updateInfo;
          this.apkUrl = response.data.apkUrl
        })
        .catch((error) => {
          console.error(error);
        });
      // this.$nextTick(() => {
      //   const marqueeContainer = this.$refs.marqueeContainer;
      //   const containerWidth = marqueeContainer.clientWidth;

      //   // 随着屏幕宽度减小，缩放值逐渐减小
      //   this.scaledValue = Math.max(0.5, containerWidth / 1550);
      // });
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.checkResize);
    },
    copyContent() {
      const contentToCopy = "contact@ticker.capital";
      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          this.$message.success("内容已复制");
        })
        .catch((error) => {
          console.error("复制失败", error);
          this.$message.error("复制失败");
        });
    },
    hoverButton() {
      this.isHovered = true;
    },
    leaveButton() {
      this.isHovered = false;
    },
    handleOuterChange(index) {
      // index 参数即为当前轮播图的索引
      this.currentIndex = index;
      // 获取轮播图的总数
      this.totalItems = this.$refs.carousel.items.length;
      setTimeout(() => {
        if (this.currentIndex === 0) {
          this.showQblackIcon = false;
        }
      }, 0);
      // 判断是否 is 6 or 5
      if (this.currentIndex === 1) {
        this.showDownIcon = false;
        this.showDownWhiteIcon = false;
        this.showTopIcon = true;
        this.showQblackIcon = true;
      } else {
        // All other cases
        this.showDownIcon = true;
        this.showDownWhiteIcon = false;
        this.showTopIcon = false;
        this.showQblackIcon = true;
        this.isLoadAnimate = false;
      }
    },
    prevSlide() {
      let that = this;

      this.$refs.carousel.prev();
    },
    // nextSlide() {
    //   let that = this;
    //   // if (this.currentIndex == 0) {
    //   this.isAnimating_top = true;
    //   this.isAnimating_down = true;
    //   setTimeout(() => {
    //     that.$refs.carousel.next();
    //   }, 100);

    //   setTimeout(function () {
    //     that.isAnimating_top = false;
    //     that.isAnimating_down = false;
    //   }, 800);
    //   // } else {
    //   //   that.$refs.carousel.next();
    //   // }
    // },
    // handleWheel: debounce(function (event) {
    //   // console.log(event.deltaY);
    //   //  根据滚动方向切换轮播
    //   if (event.deltaY < 0) {
    //     this.prevSlide();
    //   } else {
    //     this.nextSlide();
    //   }
    // }, 80),
  },
};
</script>

<style>

.logo-img {
  height: 70px;
  width: 70px;
}

.version-text {
  margin: 20px 0px;
}

.version-description {
  display: block;
  margin: 16px 0px;
}

@media screen and (max-width: 768px) {
  .el-message-box {
    width: 73% !important;
  }

  .background-image-top {
    width: 100% !important;
    height: 45% !important;
  }

  .background-image-top.animate_top {
    animation: image-top 0.7s !important;
    -webkit-animation: image-top 0.7s !important;
    /* Safari and Chrome */
  }

  .background-image-down {
    width: 100% !important;
    height: 45% !important;
  }

  .background-image-down.animate_down {
    animation: image-down 0.7s !important;
    -webkit-animation: image-down 0.7s !important;
    /* Safari and Chrome */
  }

  .item-content img {
    height: 50px !important;
    width: 50px !important;
  }

  .grow_together span {
    font-size: 14px !important;
    bottom: 20px !important;
    text-align: center;
  }

  /* .confluxdapp {
    right: 47px !important;
  } */

  .conflux_dapp {
    font-size: 28px !important;
  }

  .update_info p {
    font-size: 20px !important;
    justify-content: center;
    display: flex;
    word-wrap: break-word;
    /* 允许长单词换行 */
    white-space: pre-line;
    /* 保留换行符，并允许文本自动换行 */
  }

  .update_info .version_name {
    margin: 0 0 10px 0 !important;
    font-size: 20px !important;
  }

  .icon_div {
    height: 60px !important;
    width: 60px !important;
    font-size: 12px !important;
  }

  .icon_div img {
    height: 23px !important;
  }

  .update_info_div {
    font-size: 16px !important;
  }

  .Q-icon {
    width: 40px !important;
    height: 40px !important;
    top: 30.5% !important;
  }

  .acquisition_of {
    padding: 0 28px;
  }

  .acquisition_of img {
    height: 30px;
  }

  .title {
    font-size: 28px !important;
    line-height: 0 !important;
  }

  .carousel-item-content {
    width: 100% !important;
  }

  .title_content {
    font-size: 12.5px !important;
  }

  .searchfi {
    line-height: 0 !important;
  }

  .carousel-item-content {
    width: 100% !important;
    height: 0 !important;
  }

  .acquisition_data {
    width: 100% !important;
    height: 60px !important;
    margin-left: 0 !important;
  }

  .acquisition_data span:nth-child(2) {
    font-size: 12px !important;
    line-height: 30px;
    padding-left: 4px;
  }

  .acquisition_data span:first-child {
    font-size: 22px !important;
    line-height: 10px !important;
  }

  .learn_more {
    width: 150px !important;
    height: 36px !important;
  }

  .searchfi_eth {
    padding: 7.7px !important;
    border-radius: 12px !important;
    top: 10px;
  }

  .logo-img {
    height: 50px!important;
    width: 50px!important;
  }
}

.searchfi_eth {
  padding: 14px;
  border: 1px solid #6363634d;
  border-radius: 16px;
  cursor: pointer;
  position: absolute;
  left: 30px;
}

.searchfi {
  line-height: 50px;
}

.acquisition_of {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update_info_div {
  height: 160px;
  width: 260px;
  left: 60px;
  position: relative;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #787878;
}

.confluxdapp {
  position: absolute;
  bottom: 0px;
  z-index: 999;
  /* right: 35px; */
}

.version_name {
  margin: 0 0 20px 0;
  font-size: 18px;
}

.icon_div {
  background: #e2e2e2;
  height: 120px;
  width: 120px;
  align-items: center;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  margin: 0 20px;
  flex-direction: column;
  /* 垂直排列 */
}

.icon_div:hover {
  background: #d1d1d1;
  /* 鼠标悬停时的背景色 */
  cursor: pointer;
}

.icon_div span {
  color: #787878;
}

.icon_space {
  /* width: 180px; */
  /* flex: 0 0 auto; */
  /* 固定宽度 */
  flex: 0 0 180px;
  /* 固定宽度 */
  box-sizing: border-box;
  /* 防止添加边距导致元素宽度超出 */
}

.el-carousel__item.is-animating {
  transition: transform 1s ease-in-out !important;
}

@media (prefers-color-scheme: dark) {
  /* 暗黑模式下的样式 */
  body {
    background-color: #f5f5f5 !important;
  }
  .carousel-item {
    background: #f5f5f5 !important;
  }
  .icon_div {
    background: #e2e2e2 !important;
  }
  .icon_div span {
    color: #787878 !important;
  }
  .update_info_div {
    color: #787878 !important;
  }
  .conflux_dapp {
    color: #4a4a4a !important;
  }
  .update_info {
    color: #787878 !important;
  }
}


.el-carousel-item div,
.el-carousel-item img,
.el-carousel-item span,
.el-carousel-item i,
.el-carousel-item a,
.item-content,
.carousel-item {
  transition: opacity 0.3s ease-in-out;
}

.first-marquee,
.second-marquee,
.three-marquee {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  /* margin: 0 20px; */
  /* overflow: hidden; */
}

.three_icon {
  gap: 8px;
  width: 304px;
  height: 48px;
  align-items: center;
}

.learn_more {
  width: 160px;
  height: 48px;
  border-radius: 20px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #787878;
  background: unset !important;
  position: relative;
  overflow: hidden;
}

.learn_more::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #15255e;
  transition: height 0.3s ease;
  z-index: -1;
  /* 将 ::before 伪元素的 z-index 设置为较低值 */
}

.learn_more:hover::before {
  height: 100%;
}

.learn_more:hover {
  color: #ffffff !important;
  /* 鼠标悬停时的字体颜色 */
}

.top {
  top: -35px;
}

.bottom {
  bottom: -35px;
}

/** 设置宽，溢出隐藏  */
.marquee-outer-wrapper {
  overflow: hidden;
  width: 100%;
}

.marquee-inner-wrapper {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin: 0 auto;
  white-space: nowrap;
  position: relative;
  height: 364px;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: grid;
}

/* 定义第一个span的animation：时长 动画名字 匀速 循环 ease-in-out 正常播放 */
.first-marquee {
  -webkit-animation: marquee-animation 1s ease-out none alternate;
  animation: marquee-animation 1s ease-out none alternate;
  transform: scale(1);
}

@keyframes marquee-animation {
  0% {
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%) scale(1);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) scale(1);
  }
}

.second-marquee {
  -webkit-animation: second-animation 1s ease-out none alternate;
  animation: second-animation 1s ease-out none alternate;
  transform: scale(1);
}

@keyframes second-animation {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%) scale(1);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) scale(1);
  }
}

.three-marquee {
  -webkit-animation: three-animation 1s ease-out none alternate;
  animation: three-animation 1s ease-out none alternate;
  /* padding-right: -10%; */
  transform: scale(1);
}

@keyframes three-animation {
  0% {
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%) scale(1);
  }

  /* Move to the left */
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) scale(1);
  }
}

.seoul-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 232px;
  gap: 24px;
}

.subtitle_content1 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #4a4a4a;
  width: 119px;
  text-align: left;
}

.subtitle_content2 {
  font-family: "Amiri";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #37588a;
  width: 119px;
  text-align: left;
}

.our_team {
  /* display: flex; */
  width: 960px;
  height: 260px;
}

.out_team_title {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  color: #4a4a4a;
}

.acquisition_data {
  /* display: flex; */
  width: 935.23px;
  height: 123.03px;
  margin-left: 160px;
}

.acquisition_data div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.acquisition_data span:nth-child(2) {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #787878;
  padding-left: 4px;
}

.acquisition_data span:first-child {
  font-family: "Amiri";
  font-size: 64px;
  font-weight: 400;
  font-style: italic;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: center;
  color: #4a4a4a;
}

.carousel-item-content {
  width: 639.07px;
  height: 138px;
}

.partners {
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
}

.company_profile_content {
  width: 100%;
  height: 180px;
  display: block;
  justify-content: center;
  text-align: center;
}

.partners_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  font-family: "Poppins";
  color: #ffffff;
}

.fingerprint {
  position: absolute;
  z-index: -1;
  top: 29.5%;
}

.el-divider--horizontal {
  bottom: 13px !important;
  width: 132px !important;
  margin: 0 auto !important;
  border-top: 1px #4a4a4a var(--el-border-style) !important;
}

.el-divider {
  background-color: #4a4a4a !important;
}

.title {
  font-family: "Italiana";
  font-size: 64px;
  font-weight: 400;
  line-height: 75.39px;
  letter-spacing: 0em;
  color: #4a4a4a;
}

.title_content {
  font-family: "Amiri";
  color: #787878;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.inner-item-content {
  width: 600px;
  height: 211px;
  display: grid;
  grid-template-rows: 3fr 1fr;
  align-items: center;
  position: absolute;
}

.background-image-top {
  background-image: url("@/assets/svg/background-top.svg");
  /* 设置为 background-top.svg 的路径 */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.background-image-top.animate_top {
  animation: image-top 1s;
  -webkit-animation: image-top 1s;
  /* Safari and Chrome */
}

@keyframes image-top {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: 90%;
    top: -100%;
  }
}

@-webkit-keyframes image-top

/* Safari and Chrome */
  {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: 90%;
    top: -100%;
  }
}

.background-image-down {
  background-image: url("@/assets/svg/background-down.svg");
  /* 设置为 background-top.svg 的路径 */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.background-image-down.animate_down {
  animation: image-down 1s;
  -webkit-animation: image-down 1s;
  /* Safari and Chrome */
}

@keyframes image-down {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: -100%;
    top: 90%;
  }
}

@-webkit-keyframes image-down

/* Safari and Chrome */
  {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: -100%;
    top: 90%;
  }
}

.Q-icon {
  width: 56px;
  height: 56px;
  position: relative;
  cursor: pointer;
  top: 11.5%;
  /* right: 48%; */
  z-index: 999;
}

.update_info {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #787878;
  /* margin: 14px 0 40px; */
}

.update_info p {
  text-align: justify;
  margin-top: 0;
  /* 将上内边距设置为0 */
}

.update_info span {
  text-align: end;
  position: absolute;
  margin-left: 35px;
}

.grow_together {
  font-family: "Amiri";
  font-size: 32px;
  font-weight: 400;
  line-height: 56.26px;
  letter-spacing: 0em;
  color: #787878;
}

.conflux_dapp {
  font-family: "Amiri";
  font-size: 48px;
  font-weight: 600;
  color: #4a4a4a;
  margin: 0;
  display: grid;
  align-items: center;
}

.carousel-item {
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background: #f5f5f5 !important;
}

.company_profile {
  background: #272727 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.partners_item {
  background: #272727 !important;
  display: grid !important;
  align-items: center !important;
}

.item-content {
  /* width: 498px;
  height: 112px;
  text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 4px; */
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #f5f5f5;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #eaeaea;
}

.creator-name {
  font-family: "Amiri";
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
}

.q-image-class {
  margin-right: 10px;
}

body {
  margin: 0 !important;
}
</style>
